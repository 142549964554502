import MapComponent from "./MapGeojsonMarkers1";
// import SecondMenu from './SecondMenu';
import NaviBar from './NaviBar';

function GryntMap  () {

    return (
    <>
    <NaviBar />
    {/* <SecondMenu /> */}
    {/* <h1 className="homepage">Інформаційно-аналітична  система  екологічного 
    моніторингу  території  Роздільського  ДГХП  "Сірка"  </h1>   */}
  
  
    <MapComponent/>
   </>
    );
  }   
       
      
    
  
    export default GryntMap;