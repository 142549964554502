import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import NaviBar from './NaviBar';
// import SecondMenu from './SecondMenu';


Chart.register(zoomPlugin);

let BarChart;


function Charts4() {
    useEffect(() => {
        buildBarChart();
    }, []);

    const buildBarChart = () => {
        var ctx = document.getElementById("BarChart").getContext("2d");

        if (typeof BarChart !== "undefined") BarChart.destroy();

        BarChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['Kysle Lake', 'Chyste Lake', 'Serednie Lake', 'Hlyboke Lake'],
                datasets: [
                 {
                     type: 'bar',
                     label: 'NO3',
                     data: [13.2, 15.3, 6.3, 1.7],
                     fill: true,
                     backgroundColor: 'rgb(161, 16, 251)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'NO2',
                     data: [0.098, 0.11, 0.042, 0.013],
                     fill: true,
                     backgroundColor: 'rgb(251, 16, 231)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'NH4',
                     data: [1.9, 1.8, 1.8, 2.2],
                     fill: true,
                     backgroundColor: 'rgb(16, 251, 208)',
                     tension: 0.1
                },
                 {
                     type: 'bar',
                     label: 'Zal',
                     data: [5.2, 8.5, 7.2, 7.4],
                     fill: true,
                     backgroundColor: 'rgb(51, 7, 39)',
                     tension: 0.1
                 },
                  {
                     type: 'bar',
                     label: 'рН',
                     data: [12.0, 8.0, 8.0, 8.0],
                     fill: true,
                     backgroundColor: 'rgb(12, 133, 125)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'COD',
                     data: [23.1, 23.1, 34.6, 28.8],
                     fill: true,
                     backgroundColor: 'rgb(12, 20, 133)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'Fe',
                     data: [5.0, 0.45, 0.375, 0.375],
                     fill: true,
                     backgroundColor: 'rgb(169, 116, 204)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'Fe2+',
                     data: [4.55, 7.55, 0.000, 0.025],
                     fill: true,
                     backgroundColor: 'rgb(116, 164, 204)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'Fe3+',
                     data: [0.45, 8.0, 0.375, 0.35],
                     fill: true,
                     backgroundColor: 'rgb(219, 7, 85)',
                     tension: 0.1
                 }
                
                ]
            },
            });
        }


    return (
        <div className='graf'>
            <NaviBar />
            {/* <SecondMenu /> */}
            <h2>Результати аналізу проб води у зоні впливу Роздільського ДГХП Сірка </h2>
            <h3>Аналіз проб води в листопаді 2021 року</h3>
            <canvas id="BarChart" width="1000" height="400"/>
        </div>
    )
}



export default Charts4;