import NavMenu from "./NavMenu";

function Home  () {

   return (
    
      
    
       <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
       
         {/* <h1 className="h2">Головна </h1> */}
         <div className="btn-toolbar mb-2 mb-md-0">
          <NavMenu />
           <div className="btn-group me-2">
            {/* <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
             <button type="button" className="btn btn-sm btn-outline-secondary">Export</button> */}
           </div>
           {/* <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
             <span data-feather="calendar" className="align-text-bottom"></span>
             This week
           </button>  */}
            {/* <h3>Information and analytical system of ecological monitoring of the territory of SE "Rozdil Mining and Chemical Enterprise "Sirka"</h3> */}
            <h3>Інформаційно-аналітична система моніторингу території роздільського гірничо-хімічного підприємства "Сірка"</h3>
            <p>За отриманими результатами досліджень забрудненої території розроблено засади екологічної безпеки гірничо-хімічного підприємства на етапі ліквідації, в основу яких покладено принцип комплексного екологічного моніторингу на основі збору, зберігання та опрацювання даних про забруднення різних елементів середовища (ґрунтів, поверхневих та підземних вод, атмосферного повітря), що забезпечить прогнозування екологічних змін території гірничо-хімічного підприємства</p>  
          </div>
        </div>
        
       
       );
 }   
     
    
  

  export default Home;