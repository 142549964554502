// import logo from './logo.svg';
import './App.css';
import React from "react";
// import NavState from './navState';
// import MainMenu from './MainMenu';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
// import About from './About';
// import Users from './Users';
import Main from './Main';
import { BrowserRouter as Router } from 'react-router-dom';
import Charts1 from './Charts1';
// import MapComponent1 from "./MapGeojsonMarkers1";
// import BasicMenu from './BasicMenu';
import Charts2 from './Charts2';
import Charts3 from './Charts3';
import Charts4 from './Charts4';
import Charts5 from './Charts5';
import Charts6 from './Charts6';
import Charts7 from './Charts7';
import Grynt from './pages/Grynt';
import SecondMenu from './SecondMenu';
import Water from './pages/Water';
import GroundWater from './pages/GroundWater';
import Vidxody from './pages/Vidxody';
import GryntMap from './GryntMap';
import WaterMap from './WaterMap';
import Header from './Header';
import ImageSlider from './ImageSlider';


function App() {
  return (

<div className="App">
     <Header />
<div class="container-fluid">
  <div class="row">
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div class="position-sticky pt-3 sidebar-sticky"> 
        
        <SecondMenu />
      </div> 
    </nav>

    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/main" element={<Main/>}/>
        <Route exact path="/charts1" element={<Charts1/>}/>
        <Route exact path="/charts2" element={<Charts2/>}/>
        <Route exact path="/charts3" element={<Charts3/>}/>
        <Route exact path="/charts4" element={<Charts4/>}/>
        <Route exact path="/charts5" element={<Charts5/>}/>
        <Route exact path="/charts6" element={<Charts6/>}/>
        <Route exact path="/charts7" element={<Charts7/>}/>
        <Route exact path="/grynt" element={<Grynt/>}/> 
        <Route exact path="/water" element={<Water/>}/> 
        <Route exact path="/groundwater" element={<GroundWater/>}/> 
        <Route exact path="/vidxody" element={<Vidxody/>}/> 
        <Route exact path="/gryntmap" element={<GryntMap/>}/> 
        <Route exact path="/watermap" element={<WaterMap/>}/> 
        <Route exact path="/imageslider" element={<ImageSlider/>}/>
      </Routes>
    </Router>
      
    </main> 
  </div>
</div> 
  </div>
  );
}

export default App;
