import React from 'react';
import './SecondMenu.css';


function SecondMenu () {


    return (
        <>
      <ul className="nav flex-column">
            <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">Головна</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/grynt">Грунт</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/water">Поверхневі води</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/groundwater">Підземні води</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/vidxody">Відходи</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/Charts1">Прогноз</a>
            </li>
            </ul>


        
      
        
        </>
    )
}

export default SecondMenu;
 

