import React, { useState, useEffect} from 'react';
import { Marker, FeatureGroup, Popup } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";

var fetchDataset = function fetchDataset(url, options) {
  let request = fetch(url, options);

  return request
    .then(r => r.json())
    .then(dataset => dataset.features);
}

export default function GeojsonLayer ({url, cluster}) {
  const [dataset, setDataset] = useState([]);

  useEffect(()=> {
    if (url) {
      const abortController = new AbortController();

      fetchDataset(url, { signal: abortController.signal }).then(dataset => {
        setDataset(dataset);
      });

      // cancel fetch on component unmount
      return () => {
        abortController.abort();
      };
    }

  }, [url]);

  var GroupComponent = cluster ? MarkerClusterGroup : FeatureGroup;

  // console.info(data);
  return (
      <GroupComponent>
        {dataset.map(f => (
            <Marker
              key={JSON.stringify(f.properties)}
              position={f.geometry.coordinates}
            >
              <Popup minWidth={200} closeButton={false}>
                <div style={{backgroundColor:"blue", color:"white"}}>
                  <b>Проби грунту</b>
                  <p>Точка відбору: {f.properties.name}</p>
                </div>
              </Popup>
            </Marker>
        ))}
      </GroupComponent>
  );
}