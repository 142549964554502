


function NaviBar() {
  return (
      
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
       <div className="btn-toolbar mb-2 mb-md-0">
         <div className="btn-group me-2">

         <ul className="nav justify-content-end">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="/watermap">Карта</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/charts3">Графік3</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/charts4">Графік4</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/charts5">Графік5</a>
        </li>
      </ul>
      </div>  

  </div>          
</div>
  );
}

export default NaviBar;