import NaviBar from '../NaviBar';
// import SecondMenu from '../SecondMenu';


function Water  () {

  return (
  <>
  <NaviBar />
  {/* <SecondMenu /> */}
  <h1 className="karta">Аналіз проб поверхневих вод</h1> 
  <p>Поверхневі води 
Північний кар’єр розділено на три частини. На заході розташоване озеро 
Чисте, яке утворилось у відокремленій частині кар’єру. Абсолютна відмітка 
води в озері становить 270 м, глибина озера 17 м. Воно живиться 
атмосферними опадами та джерелами, які витікають на високому західному 
березі. В дощові періоди вода із озера витікає в річку Колодницю.
Озеро Середнє займає найбільшу площу. Тут міститься 
10,6 млн м
3
тонких фракцій відходів збагачення сірчаної руди. Товщина шару 
відходів 33 м. Потім виїмку заповнили оборотні води й атмосферні опади, 
утворилось озеро глибиною до 16 м. Об’єм води 9,9 млн м3
, абсолютна 
відмітка рівня води близько 270,5 м, площа 93,5 га. Щоб покращити ситуацію 
на озері, потрібно понизити рівень води до 265 м, щоб зменшити глибину озера 
до 7-10 м. За такої глибини сонячні промені досягають дна водойми і на дні 
активно розвиваються водорості, які служать кормом для риб.
Східну частину кар’єру затоплено до відмітки 253 м, тут утворилося 
озеро Глибоке. Водозбірна площа озера 280 га, площа 82 га. Його назва 
свідчить про велику глибину, яка досягає 40 м. Хвостосховище №2 створено у залишковій виїмці Північного кар'єру. На 
дні залягають гіпсоангідрити, як перекриті відсипкою та утрамбуванням шару 
глини. Борти, в яких відслонювались вапняки, також присипані глиною. Вище 
у бортах відслонюються корінні виходи глин та мергелів або внутрішні 
відвали. Водні об’єкти Роздільського ДГХП "Сірка" за гідрохімічним індексом 
належать до вод із задовільним (р. Дністер вище і нижче скидів підприємства), 
поганим (канал оз. Глибоке – Дністер) і дуже поганим (канал відходу дощових 
вод) екологічним станом. Як свідчать отримані дані, у поверхневому шарі вод всіх Роздільських 
озер фіксується перевищення нормативних показників за мінералізацією 
(ГДК – 1000 мг/дм
3
) та сульфатами (ГДК – 100 мг/дм
3
), а в оз. Кислому та 
оз. Середньому – і за фосфатами (ГДК – 0 мг/дм
3
), в оз. Кислому pH становить 
5,25 при нормі від 6,5 – до 8,5 [170, 137].
У каналі озера Глибоке-Дністер встановлено перевищення ГДК за 
такими показниками: рН становить 6,05 (норма – від 6,5 – до 8,5), сульфати –
1665,3 мг/дм3 (ГДК – 100 мг/дм
3
), азот амонійний – 4,6 мг/дм3
(ГДК –
0,5 мг/дм
3
), мінералізація – 2498,6 мг/дм3 (ГДК – 1000 мг/дм
3
). 
Отже, як свідчать отримані дані, у поверхневому шарі вод всіх 
Роздільських озер фіксується перевищення нормативних показників. Водні 
об’єкти Роздільського ДГХП "Сірка" за гідрохімічним індексом належать до 
вод із задовільним, поганим і дуже поганим екологічним станом.
</p>

  {/* <MapComponent/>  */}
  
  </> 
  );
}   

  export default Water;