import './Main.css'
import NavMenu from './NavMenu';




function Main () {
    return (
      <>
      <NavMenu />
      <h3 className="about__project">Про проект</h3>
      <p className="project__description">
      Одним із промислових басейнів, що зазнали інтенсивної експлуатації, є Передкарпатський сірконосний басейн. Основними об’єктами в межах сірконосного басейну, для яких виконували гірничодобувні роботи, були Роздільське та Яворівське ДГХП "Сірка". ДГХП  «Сірка»,  яке  було  засноване  у  1953  році  у  зв’язку  із освоєнням досить великого Роздільського родовища. Це підприємство бурхливо розвивалось у 50-ті – 80-ті роки ХХ століття, коли були відкриті поклади сірчаних руд, проведена їхня геологічна розвідка, почергово  введено  в  експлуатацію    три  кар’єри  (Південний,  Центральний  та Північний),  побудовано  збагачувальну  фабрику,  завод  складних  мінеральних  добрив,  місця  складування  промислових  відходів,  а  обсяги  виробництва мінеральних добрив становили близько 800 тисяч тонн в рік.  
На початку 1990-х років видобуток сірки припинився, розпочався процес реструктуризації гірничо-хімічного підприємства на площі близько 22 тис. га та має  розвинену  інфраструктуру,  систему  енергопостачання  та  водопостачання, залізницю. Станом на 2000 рік Роздільське сірчане родовище вже було повністю відпрацьовано – усі розвідані поклади вилучені з надр, порушені гірничими роботами ландшафти в межах    досліджуваної    території рекультивуються та приводяться у безпечний для людей та довкілля стан у відповідності до розробленого комплексного проєкту. Через ненормований видобуток сірки відкритим способом на місці колишніх родовищ сірки нині залишився  спотворений  природний  ландшафт,  техногенні  відходи  та забруднені ґрунти та водні об’єкти
      </p>
      <p>
      <h5 className='title'>На території Роздільського ДГХП "Сірка" виділено 11 джерел небезпеки:</h5>
      <ol>
        <li>фосфогіпс</li>
        <li>комова сірка</li>
        <li>хвости флотації</li>
        <li>затоплені кар'єри</li>
        <li>осади оборотних вод</li>
        <li>модифікатор типу "МГ", виготовлений з нейтралізованих гудронних залишків та котлових залишків ангідриту малеїнової кислоти</li>
        <li>сміттєзвалище</li>
        <li>поверхневі води</li>
        <li>підземні води</li>
        <li>забруднені ґрунти</li>
        <li>екзогенні геологічні процеси та спотворення рельєфу</li>
      </ol>
      </p>
      </>
  );
}

  export default Main;