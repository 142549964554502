// import NaviBar2 from '../NaviBar2';
// import SecondMenu from '../SecondMenu';

function GroundWater  () {

  return (
  <>
  {/* <NaviBar2 /> */}
  {/* <SecondMenu /> */}
  <h1 className="karta">Аналіз проб підземних вод</h1> 
  <p>Досліджено склад підземних вод за вмістом хімічних елементів. У 
п’ятьох свердловинах встановлено перевищення норми за різними 
показниками. Зокрема, рівень мінералізації становить 3120 мг/дм3
і 
3588 мг/дм3
за норми 1000 мг/дм3
. Як видно з графіка при високому значенні 
pH спостерігається низький вміст мінералізації. Вміст сульфатів становить 1850 мг/дм3
і 1550 мг/дм3
за норми 500 мг/дм3
(рис. 4.11). Вміст фосфатів – 216 мг/дм3
(норма – 3,5 мг/дм3
), хлоридів –
813,6 мг/дм3
(норма – 350 мг/дм3
)

</p>

  {/* <MapComponent/>  */}
  
  </> 
  );
}   

  export default GroundWater;