// import Charts7 from '../Charts7';
import NaviBar1 from '../NaviBar1';
// import SecondMenu from '../SecondMenu';

function Grynt  () {

  return (
  <>
  <NaviBar1 />
  {/* <SecondMenu /> */}
  <h1 className="karta">Аналіз проб грунту</h1> 
  <p>Проби, які відібрано в 2016 р. на території Роздільського ДГХП "Сірка", 
проаналізовано мас-спектральним методом. Проби ґрунтів, які відібрано в 2017 р., проаналізовано за допомогою 
методу рентгенівської дифракції (XRD). Результати вимірювань отримано за різними 
показниками, зокрема, проаналізовано такі елементи, як: Стронцій, Манган, 
Цинк, Плюмбум, Арсен.</p>

  {/* <MapComponent/>  */}
  
  {/* <Charts7 /> */}
  </> 
  );
}   

  export default Grynt;